<template lang="pug">
  #sticker-page

    //-**************************************************
    //-***************** Console ************************
    //-**************************************************

    .grid-layout-container.control-panel.alignment-block.pb-6(style="width: 220px; position: fixed; top : 40px; left: 80%;")
      vs-row.pt-4(vs-w='12' vs-type='flex', vs-justify='flex-start')
          vs-col.px-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' style="color: rgb(35, 114, 206);"  )
            span(style="margin-right:10px; ") Order : {{orderInfo.orderHeader.orderNumber}}

      vs-row.pt-4(vs-w='12' vs-type='flex', vs-justify='flex-start')
          vs-col.px-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' style="color: rgb(35, 114, 206);"  )
            span(style="margin-right:10px; ") ลูกค้า : {{customerInfo.customerName}}

      vs-row.pt-4(vs-w='12' vs-type='flex', vs-justify='flex-start')
        vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='8', vs-sm='8', vs-xs='12'   )
          span(style="margin-right:10px; ") ร้านค้าส่ง
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='4', vs-sm='4', vs-xs='12'   )
          vs-switch(  v-model='switch1'  style="" )
      vs-row.pt-4(vs-w='12' vs-type='flex', vs-justify='flex-start')
        vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='8', vs-sm='8', vs-xs='12'   )
          span(style="margin-right:10px; ") ลูกค้าส่งกลับ
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='4', vs-sm='4', vs-xs='12'   )
          vs-switch(  v-model='switch2'  style="" )

      vs-row.pt-4(vs-w='12' vs-type='flex', vs-justify='flex-start')
        vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='8', vs-sm='8', vs-xs='12'   )
          span(style="margin-right:10px; ") ใบจัดชุด
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='4', vs-sm='4', vs-xs='12'   )
          vs-switch(  v-model="switchPreparing"  style="" )



      vs-row.pt-6(vs-w='12' vs-type='flex', vs-justify='flex-start')
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12'  )
            vs-button(vs-type="flex" vs-align="center" vs-justify="center" style=" width:100%; padding-left:0; padding-right:0; background: rgb(74, 74, 74); " @click="printInvoice()"  type="filled"  )
              i.material-icons(style="font-size:18px; margin-right:5px;vertical-align:middle;") print
              span พิมพ์


    //-**************************************************
    //-***************** ร้านส่งให้ลูกค้า ********************
    //-**************************************************

    .grid-layout-container.card-border.alignment-block.py-6.sticker-container(style="width:100%; color:black; ", v-if="switch1" )
      vs-row.px-2.pt-6(vs-w='12' vs-type='flex', vs-justify='center' )
        vs-col.px-10.pt-1.pl-8(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12', style="font-size: 1.1rem;" ) จาก
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{shopInfo.name}}
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{aBranch.address}}
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{shopInfo.phone}}


      vs-row.px-2.pt-12(vs-w='12' vs-type='flex', vs-justify='center' )
        vs-col.px-10.pt-1.pl-8(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12', style="font-size: 1.1rem;" ) กรุณาส่ง

        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerName}}
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerAddress}}
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerPhone}}



      .poweredby( style="font-size: 0.7rem;  " ) Powered by Alex Rental App



      //- vs-row.px-2.pt-20(vs-w='12' vs-type='flex', vs-justify='center' )
      //-   .img-container.w-64.mx-auto.flex.items-center.justify-center.px-6(vs-type='flex', vs-w='12', vs-justify='flex-start', vs-align="flex-start" , style="width: 100%;")
      //-       img.pb-6.responsive(src="/thanks.png" alt="img" style="width: 100%")






    //-**************************************************
    //-***************** ลูกค้าส่งกลับ **********************
    //-**************************************************

    .grid-layout-container.card-border.alignment-block.py-6.sticker-container(style="width:100%; color:black; " , v-if="switch2" )
      vs-row.px-2.pt-6(vs-w='12' vs-type='flex', vs-justify='center' )
        vs-col.px-10.pt-1.pl-8(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12', style="font-size: 1.1rem;" ) จาก
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerName}}
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerAddress}}
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerPhone}}


      vs-row.px-2.pt-12(vs-w='12' vs-type='flex', vs-justify='center' )
        vs-col.px-10.pt-1.pl-8(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12', style="font-size: 1.1rem;" ) กรุณาส่ง
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{shopInfo.name}}
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{aBranch.address}}
        vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{shopInfo.phone}}

      .poweredby( style="font-size: 0.7rem;  " ) Powered by Alex Rental App




    //-**************************************************
    //-***************** ใบจัดชุด ********************
    //-**************************************************

    .grid-layout-container.card-border.alignment-block.py-6.sticker-container.preparing(style="width:100%; color:black; ", v-if="switchPreparing" )
      RecPageSticker


      .poweredby( style="font-size: 0.7rem;  " ) Powered by Alex Rental App



    //-**************************************************
    //-***************** ใบส่งของ **********************
    //-**************************************************

    //- .grid-layout-container.card-border.alignment-block.py-6.sticker-container(style="width:100%;" , v-if="switch2" )
    //-   vs-row.px-2.pt-6(vs-w='12' vs-type='flex', vs-justify='center' )
    //-     vs-col.px-10.pt-1.pl-8(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12', style="font-size: 1.1rem;" ) จาก
    //-     vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{shopInfo.name}}
    //-     vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{aBranch.address}}
    //-     vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{shopInfo.phone}}


    //-   vs-row.px-2.pt-12(vs-w='12' vs-type='flex', vs-justify='center' )
    //-     vs-col.px-10.pt-1.pl-8(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12', style="font-size: 1.1rem;" ) กรุณาส่ง
    //-     vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerName}}
    //-     vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerAddress}}
    //-     vs-col.px-10.pt-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12' ) {{customerInfo.customerPhone}}


    //-   .poweredby( style="font-size: 0.8rem;  " ) Powered by Alex Rental App




</template>

<script>
// import shapeFormat from "@/mixins/shapeFormat.js";
import shapeFormat from "../../mixins/shapeFormat.js";
import VueBarcode from "vue-barcode";
import AddImageComponent from "@/views/wintershopback/AddImageComponent.vue";
import RecPageSticker from "@/views/pages/RecPageSticker.vue";


const { QRCanvas } = require("qrcanvas-vue");

export default {
  mixins: [shapeFormat],
  data() {
    return {
      switch1: true,
      switch2: true,
      switchPreparing: true,
      qrPrint: 2,
      pageNum: 1,
      pageNumQr: 1,
      pageSize: 12,
      productList: [],
      transformList: [],
      transformListQr: [],
      categories: [],
      textures: [],
      colors: [],
      sizes: [],

      list: [],
      orderInfo: {},
      customerInfo: {},
      shopInfo: {},
      aBranch: {},

      imageCompCode: 0,
      imageCompCode2: 0
    };
  },

  watch: {
    // switch1() {
    //   if (this.switch1 === true) {
    //     this.qrPrint = 2;
    //   } else {
    //     this.qrPrint = 1;
    //   }
    // }
  },

  async created() {
    let orderid = this.$route.params.orderid;

    await this.comeback(orderid);
    // var color_res = null;
    // var size_res = null;
    // try {
    //   color_res = await this.$http.get(
    //     this.$store.state.apiURL + "/api/color",
    //     this.$store.state.auth.apiHeader
    //   );
    //   size_res = await this.$http.get(
    //     this.$store.state.apiURL + "/api/size",
    //     this.$store.state.auth.apiHeader
    //   );
    // } catch (err) {
    //   color_res = null;
    //   size_res = null;
    // }

    // this.categories = this.$store.getters["productGroup/categories"];
    // this.textures = this.$store.getters["productGroup/textures"];
    // this.colors = color_res.data;
    // this.sizes = size_res.data;

    // console.log('CREATED this.colors >>> ',this.colors);
  },

  computed: {
    // pageSizeQr: {
    //   get() {
    //     return Math.ceil(this.pageSize / 2);
    //   }
    // }
  },
  async mounted() {
    // await this.$store.dispatch("productList/fetchList");
    // this.productList = this.$store.getters["productList/catalog"];
    // console.log("productList >>> ", this.productList);

    this.$emit("setAppClasses", "invoice-page");

    // this.transformList = this.transform(this.productList);
    // // console.log("transformList >>> ", this.transformList);
    // this.pageNum = this.transformList.length;

    // this.transformListQr = this.transformForQr(this.productList);
    // // console.log("transformListQr >>> ", this.transformListQr);
    // this.pageNumQr = this.transformListQr.length;

    // ****** QR Code
  },

  methods: {
    async comeback(orderid) {
      // console.log('@@@@@@@@@@@@@Comback');

      var list = [];
      // this.theLogo = this.$store.state.defaultStore + "logo.png";
      this.orderHeaderId = this.$route.params.orderid;
      this.currentUser = JSON.parse(localStorage.getItem("username"));

      var resp = null;
      try {
        resp = await this.$http.post(
          this.$store.state.apiURL + "/api/orderheader/receipt-info/" + orderid,
          {},
          this.$store.state.auth.apiHeader
        );
        this.orderInfo = resp.data;

        this.detail = this.orderInfo.orderDetail;
        // var dlist = this.orderInfo.orderDetail;

        console.log("this.orderInfo >>>>> ", this.orderInfo);

        // console.log('@@@@@@@@@@@@@Comback this.netPrice ', this.netPrice);
        // console.log('@@@@@@@@@@@@@Comback this.vatSystem ', this.vatSystem);
        // console.log('@@@@@@@@@@@@@Comback this.inVat ', this.inVat);

        // for (var d = 0; d < dlist.length; d++) {
        //   var theItm = {};
        //   theItm = Object.assign({}, dlist[d]);
        //   theItm.removed = dlist[d].flag1;
        //   this.displayItems.push(theItm);
        //   this.orders.push(theItm);
        //   list.push(theItm);
        // }

        this.shopInfo = this.orderInfo.shopInfo;

        this.aBranch = this.orderInfo.branch;

        this.customerInfo = {
          customerId: this.orderInfo.orderHeader.customerId,
          customerName: this.orderInfo.orderHeader.customerName,
          customerPhone: this.orderInfo.orderHeader.customerPhone,
          customerAddress: this.orderInfo.orderHeader.customerAddress
        };

        // this.aBranch = this.orderInfo.branch;
      } catch (err) {
        // this.$swal({
        //   icon: "warning",
        //   title: "ไม่สามารถรับข้อมูลได้"
        // });
      }

      //***********************************************************/
      //***********************************************************/
      //***********************************************************/

      return list;
    },

    theOptions(code) {
      const options = {
        cellSize: 5,
        correctLevel: "H",
        data: code
      };

      return options;
    },
    getName15Char(text) {
      if (text === null || text === undefined) {
        return "ไม่ระบุ";
      }
      return text.substring(0, 15);
    },
    getName18Char(text) {
      if (text === null || text === undefined) {
        return "ไม่ระบุ";
      }
      return text.substring(0, 18);
    },
    getName20Char(text) {
      if (text === null || text === undefined) {
        return "ไม่ระบุ";
      }
      return text.substring(0, 20);
    },
    getName12Char(text) {
      if (text === null || text === undefined) {
        return "ไม่ระบุ";
      }
      return text.substring(0, 11);
    },
    getName7Char(text) {
      if (text === null || text === undefined) {
        return "ไม่ระบุ";
      }
      return text.substring(0, 6);
    },
    getColor(code) {
      // console.log('Colors >>>> ',this.colors);

      var co = this.colors.find(x => x.code === code);
      if (co === null || co === undefined) {
        return "ไม่ระบุสี";
      } else {
        return co.description;
      }
    },
    getSize(code) {
      var co = this.sizes.find(x => x.code === code);
      if (co === null || co === undefined) {
        return "ไม่ระบุขนาด";
      } else {
        return co.code;
      }
    },
    transform(list) {
      var transform_list = [];

      var inlist = [];

      for (let i = 0; i < list.length; i++) {
        if (i % this.pageSize === 0) {
          inlist = [];
        }

        inlist.push(list[i]);

        if (i % this.pageSize === this.pageSize - 1 || i === list.length - 1) {
          transform_list.push(inlist);
        }
      }

      return transform_list;
    },

    transformForQr(list) {
      var transform_list = [];

      var inlist = [];

      for (let i = 0; i < list.length; i++) {
        if (i % this.pageSizeQr === 0) {
          inlist = [];
        }

        inlist.push(list[i]);

        if (
          i % this.pageSizeQr === this.pageSizeQr - 1 ||
          i === list.length - 1
        ) {
          transform_list.push(inlist);
        }
      }

      return transform_list;
    },

    printInvoice() {
      window.print();
    }
  },
  components: { VueBarcode, QrCanvas: QRCanvas, AddImageComponent, RecPageSticker }
  // mounted() {}
};
</script>

<style lang="scss" scpoe>
.page {
  // width: 210mm;
  // min-height: 291mm;
  // padding: 4mm 4mm 2mm;
  margin: 0 auto;
  left: 0;

  border: 0 white solid;
  /* border-radius: 5px; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}

.preparing.page{
  width: 10% !important;
}

.pagebreak {
  clear: both;
  page-break-after: always !important;
}
.sticker-container {
  background-color: white;
  margin: 0 auto !important ;
  font-family: "SukhumvitSet-Medium";
  font-size: 1.3rem;
  width: 100mm !important;
  height: 150mm !important;
  position: relative !important;
}

.poweredby {
  position: absolute;
  width: 40mm;
  top: 140mm;
  left: 55mm;
}

.card-border {
  border: 1px black solid;
}


// ********************************* //
// *********** FOR PAPER *********** //
// ********************************* //

@media print {
  @page {
    size: 100mm 150mm;
  }
  html,
  body {
    // width: 297mm;
    // height: 210mm;

    width: 100mm !important;
    height: 150mm !important;
  }
  body {
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

    // margin-top: 5mm !important;
    // background-color: black;
  }
  /* ... the rest of the rules ... */
  .sticker-container {
    background-color: white;
    margin: 0 auto !important ;
    font-family: "SukhumvitSet-Medium";
    position: relative !important;
    font-size: 1.3rem;
    width: 100mm !important;
    height: 149mm !important;
  }
  .card-border {
    border: none !important;
  }
  .control-panel {
    display: none;
    visibility: hidden;
  }

  .poweredby {
    position: absolute;
    width: 40mm;
    top: 140mm;
    left: 55mm;
  }
}
</style>
