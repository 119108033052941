
<template>
  <div class="px-2">
    <!-- <div class="grid-layout-container control-panel alignment-block pb-6" style="width: 100%; ">

        <vs-row class="pt-6" vs-w="12" vs-type="flex" vs-justify="flex-start">
            <vs-col class="px-1" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
                <vs-button vs-type="flex" vs-align="center" vs-justify="center" style=" width:100%; padding-left:0; padding-right:0; background: rgb(74, 74, 74); " @click="printNote()" type="filled"><i class="material-icons" style="font-size:18px; margin-right:5px;vertical-align:middle;">print</i><span>พิมพ์</span></vs-button>
            </vs-col>
        </vs-row>
    </div> -->

    <!-- HEADER -->
    <div class="page-sticker" style="font-size: 0.7rem;">
      <div
        id="facility-card"
        class="grid-layout-container alignment-block"
        style="font-family: mitr; vertical-align:text-top ;height:60%; "
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
          <!-- ชื่อผู้เช่า -->
          <vs-row vs-w="12" style="margin-top:30px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >ชื่อผู้เช่า: {{ this.customerName }} </vs-col>

          </vs-row>
          <!-- เบอร์ติดต่อ -->
          <vs-row vs-w="12" style="margin-top:5px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >เบอร์ติดต่อ: {{ this.customerPhone }}</vs-col>

          </vs-row>
          <!-- ที่อยู่ -->
          <vs-row vs-w="12" style="margin-top:5px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >ที่อยู่ : {{ this.customerAddress }}</vs-col>


            </vs-row>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
              <!-- ใบเสร็จรับเงิน -->
              <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
                <vs-col
                  class="theLabel pr-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                ></vs-col>
              </vs-row>
              <!-- เบอร์ติดต่อ -->
              <vs-row vs-w="12" style="margin-top: 5px;">
                <vs-col
                  class="theLabel pr-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  เลข Order &nbsp;
                  <span style="font-weight: light;">{{this.orderNumber}}</span>
                </vs-col>
              </vs-row>
              <!-- วันที่ออก -->
            </vs-row>
            <!--  ปุ่ม -->
            <!-- <vs-row vs-w="12" vs-type="flex" vs-justify="center">
            <vs-button color="success" type="filled">บันทึกจัดชุดเรียบร้อยแล้ว</vs-button>
            </vs-row>-->
            <!--------------------------------------------------------------------->
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-start"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <!-- ปุ่ม PRINT -->
            <vs-row vs-w="12" style="margin-top:30px;">
              <!-- <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0;"
              color="rgb(255, 114, 131)"
              type="filled"
              :href="getRedirectLink(this.orderHeaderId)"
              target="blank"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >print</i>
              </span>
              <span style="vertical-align:middle;">พิมพ์ใบเสร็จ</span>
              </vs-button>-->
            </vs-row>
            <!-- กำหนดรับ -->
            <vs-row vs-w="12" style="margin-top:30px;">
              <vs-col
                class="theLabel pr-2"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
              >กำหนดรับ </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{formatDate(this.startDate)}}</vs-col>
            </vs-row>
            <!-- กำหนดคืน -->
            <vs-row vs-w="12" style="margin-top:5px;">
              <vs-col
                class="theLabel pr-2"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="5"
                vs-sm="5"
                vs-xs="5"
              >กำหนดคืน</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{formatDate(this.endDate)}}</vs-col>
            </vs-row>
            <vs-row
              style="margin-top: 20px; text-align:right;  font-weight: bold;"
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
            >{{shopinfo.name}}  {{ aBranch.branchName }}</vs-row>
          </vs-col>
        </vs-row>
      </div>

      <!-- ตาราง -->
      <vs-table hoverFlat stripe :data="orders" class="pt-2" >


        <template slot-scope="{data}">
          <vs-tr class="table-font" style="overflow: hidden !important;" :data="tr" :key="indextr" v-for="(tr, indextr) in orders">
            <vs-td :data="data[indextr].mainImage" class=" px-1">
              <img
                class="responsive rounded"
                :src="getImagepath(data[indextr].mainImage)"
                alt="user-upload"
                style="width:auto; height:40px;"
              />
            </vs-td>

            <vs-td style="font-size:0.7rem; " :data="data[indextr].name">
              {{data[indextr].name}}
              <br />


              <span style="font-size:9px;">({{data[indextr].code}})</span>
              <br />


              <span style="font-size:0.7rem; padding-right: 4px;">สี: {{getColor(data[indextr])}}</span>

              <span style="font-size:0.7rem;"> ไซส์: {{getSize(data[indextr])}}</span>
              <br />

              <span style="font-size:0.8rem; font-weight: bold;">จำนวน {{data[indextr].reservedQuantity}}</span>
              <br />


              <i
                v-if="getRemoved(indextr) === 'y'"
                class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                style="order: 0;text-align:left;  font-size:25px; color:red; cursor:pointer;"
              >cancel</i>
            </vs-td>

            <!-- <vs-td
              style="font-size:12px;"
              :data="data[indextr].reservedQuantity"
            >{{data[indextr].reservedQuantity}}</vs-td> -->


            <vs-td style="font-size:0.7rem;">
              <div class="notChoose">เช็คของ</div>
            </vs-td>
            <!-- <vs-td style="font-size:12px;">
              <div class="notChoose">เช็คของ</div>
            </vs-td> -->
          </vs-tr>
        </template>
      </vs-table>

      <!-- BEGIN: MONETARY SUMMATION -->
      <div class="grid-layout-container alignment-block" style="width:100%; margin-top: 20px;">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-lg="7"
            vs-sm="7"
            vs-xs="7"
          >
            <vs-row style="margin-top:10px;" vs-w="12">
              <i
                style="font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; "
                class="material-icons iconhover"
              >local_shipping</i>
              วิธีรับชุด : {{receivingMethod}}
              <br />
              <br />
              หมายเหตุ :
              {{remark1}}
            </vs-row>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
          >

          </vs-col>
        </vs-row>
      </div>
      <!-- END: MONETARY SUMMATION -->

      <!--------------------------------------------------------------------->
      <!--------------------------------------------------------------------->
      <!-- <div style="border-top: 1px solid #E3E3E3; margin-bottom:40px; "></div> -->
      <!--------------------------------------------------------------------->

      <!--  ปุ่ม -->
      <!-- <vs-row vs-w="12" vs-type="flex" vs-justify="center">
      <vs-button color="success" type="filled">บันทึกจัดชุดเรียบร้อยแล้ว</vs-button>
      </vs-row>-->
      <!--------------------------------------------------------------------->
    </div>
  </div>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";

import CurrencyValue from "../wintershopback/Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";
import VueBarcode from "vue-barcode";

// import jsPDF from "jspdf";

// import html2canvas from "html2canvas";

const dict = {
  custom: {
    name: {
      required: "ยังไม่ได้ใส่ชื่อลูกค้า"
    }
  }
};

Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  props: {
    // list: Array,
    noworderid: String
  },
  data() {
    return {
      detail: [],
      orderid: "",
      checkAll: false,
      aBranch: {},
      termandcondition: "",
      watermark: true,
      startDate: null,
      endDate: null,

      orderHeaderId: "",
      theLogo: "",
      issuedDate: new Date(),
      displayItems: [],
      branchStock: [],
      itemsInBranch: [],
      // 1. ORDER HEADER
      orderNumber: "",
      orderStatus: "", // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed
      remark: "",
      remark1: "",

      // 2. CUSTOMER
      customerInfo: "",
      customerName: "",
      customerAddress: "",
      customerPhone: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      transferContact: "",

      // 3. DELIVERY
      howtoreceive: "มารับเอง", // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
      receivingDate: null,
      receivingMethod: "",
      returnDate: null,
      addtocart: "",

      // 4. MONETARY
      rentalPriceTotal: 0,
      bailTotal: 0,
      grandTotal: 0,
      bailDiscount: 0,
      rentalDiscount: 0,
      promotionDiscount: 0,
      rentalDiscountPercent: 0,
      bailDiscountPercent: 0,
      deduction: 0,
      deducingDate: null,

      // 5. LIST
      originalList: [],
      select_order: "OR1903130042",
      order_option: [
        { text: "OR1903130042", value: "OR1903130042" },
        { text: "OR1903130041", value: "OR1903130041" },
        { text: "OR1903130040", value: "OR1903130040" }
      ],
      orders: [],
      shopinfo: {
        name: "",
        phone: "",
        line: "",
        termAndCondition: ""
      },
      colors: [],
      categories: [],
      sizes: [],
      textures: [],

      products: [],
    };
  },

  async mounted() {
    this.orderid = this.$route.params.orderid;
    this.$vs.loading();
    this.theLogo = this.$store.state.defaultStore + "logo.png";
    this.orderHeaderId = this.orderid;
    // console.log("@@@@@ IN TODO-ORDER-DETAIL >>>> ", this.orderid);

    this.currentUser = JSON.parse(localStorage.getItem("username"));



    // BEGIN >>>>>>> INITAILZE ITEMS IN BRANCH : params : {thisBranch} >>>>>>>>>

    await this.comeback(this.orderHeaderId);

    this.orders = await this.displayItems;

    // console.log("this.orders >>> ",this.orders);

    var resShop = await this.loadShopInfo();
    if (resShop !== null) {
      this.shopinfo = await resShop.data;
    }

    await this.$store.dispatch(
      "productGroup/getAllData",
      this.shopinfo.shopInfoId
    );

    await this.$store.dispatch(
      "productList/fetchList",
      this.shopinfo.shopInfoId
    );

    this.categories = this.$store.getters["productGroup/categories"];
    this.textures = this.$store.getters["productGroup/textures"];
    this.colors = this.$store.getters["productGroup/colors"];
    this.sizes = this.$store.getters["productGroup/sizes"];


    // console.log("this.colors >>> ",this.colors);

    this.products = this.$store.getters["productList/products"]

    // console.log("this.products >>> ",this.products);





    setTimeout(() => {
      this.$vs.loading.close();
    }, 200);

    // setTimeout(() => {
    //   window.print();
    // }, 4000);
  },
  methods: {

    printNote(){
      window.print();
    },

    getColor(ordItem){
      let result = this.products.filter(
        (x) => x.code === ordItem.code
      );

      if(result === null ||result === undefined|| result.length <= 0 )
      {
        return "ไม่ระบุ"
      }

      // console.log("result >>> ",result);

      // get first one
      let firstone = result[0];

      // console.log("this.colors >>> ",this.colors);

      let filteredColor = this.colors.filter(
        (xx) => xx.code === firstone.color
      );


      if(filteredColor === null ||filteredColor === undefined|| filteredColor.length <= 0 )
      {
        return "ไม่ระบุ"
      }

      // console.log("filteredColor >>> ",filteredColor);

     return filteredColor[0].description;
    },

    getSize(ordItem){
      let result = this.products.filter(
        (x) => x.code === ordItem.code
      );

      if(result === null ||result === undefined|| result.length <= 0 )
      {
        return "ไม่ระบุ"
      }

      // console.log("result >>> ",result);

      // get first one
      let firstone = result[0];

      // console.log("this.colors >>> ",this.colors);

      let filteredColor = this.sizes.filter(
        (xx) => xx.code === firstone.size
      );


      if(filteredColor === null ||filteredColor === undefined|| filteredColor.length <= 0 )
      {
        return "ไม่ระบุ"
      }

      // console.log("filteredColor >>> ",filteredColor);

     return filteredColor[0].description;
    },

    getRemoved(ind) {
      return this.detail[ind].flag1;
    },
    async savePrepared(orderid) {
      this.$vs.loading({ type: "radius" });
      // >>>> START TO SAVE HEADER
      var res = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/prepareorder/" + orderid,
          {
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        res = null;
      }

      if (res !== null) {
        this.orderHeader = await res.data;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("บันทึกเตรียมชุดเรียบร้อยแล้ว");
          this.$emit("saveprepare", true);
        }, 300);
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("บันทึกไม่สำเร็จ");
          this.$emit("saveprepare", false);
        }, 300);
      }
    },
    checklist(event, indextr, item) {
      this.checkAll = false;
      if (event.target.className == "choose") {
        this.orders[indextr].checked = false;
        event.target.className = "notChoose";
      } else {
        event.target.className = "choose";
        this.orders[indextr].checked = true;
      }

      console.log("CHECK ITEM >>> ", item.checked);
      // console.log("ALL ITEMs >>> ", this.orders);
      var chk = true;
      for (let index = 0; index < this.orders.length; index++) {
        chk = chk && this.orders[index].checked;
      }
      if (chk === true) this.checkAll = true;
      // console.log("chk >>> ", chk, " this.checkAll >>>>> ", this.checkAll);
    },
    async loadShopInfo() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/shopinfo",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      return res;
    },
    getRedirectLink(orderId) {
      var ff = "t";
      if (this.watermark === false) {
        ff = "f";
      } else {
        ff = "t";
      }
      return "/pages/rec/" + orderId + "/" + ff;
    },
    theLastTotalPrice() {
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);

      var total = isNaN(Number(this.rentalPriceTotal))
        ? 0
        : Number(this.rentalPriceTotal);

      // console.log("total : ", total, " pd : ", pd, " rd : ", rd);

      return total - (pd + rd);
    },
    theLastBail() {
      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);

      var total = isNaN(Number(this.bailTotal)) ? 0 : Number(this.bailTotal);
      return total - bd;
    },
    conditionImage() {
      return this.$store.state.defaultStore + "condition.png";
    },
    alreadycheckedImage() {
      return this.$store.state.defaultStore + "alreadychecked.png";
    },
    rentalImage() {
      return this.$store.state.defaultStore + "rental.png";
    },
    bailImage() {
      return this.$store.state.defaultStore + "bail.png";
    },
    returnImage() {
      return this.$store.state.defaultStore + "return.png";
    },

    backtorental() {
      this.$router.push("/backoffice/rental/" + this.orderHeaderId);
    },
    async update() {
      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      var ord = {
        // orderHeaderId: "string",
        // orderRef: "string",
        orderNumber: this.orderNumber,
        // orderNumberRef: "string",
        branchId: this.currentBranchId,
        orderStatus: "รอรับชุด",
        remark1: this.remark1,
        // remark2: "string",
        // remark3: "string",
        // remark4: "string",
        customerId: "string",
        customerName: this.customerName,
        customerAddress: this.customerAddress,
        customerPhone: this.customerPhone,
        // accountNumber: "string",
        // accountName: "string",
        // bankName: "string",
        // transferContact: "string",
        receivingMethod: this.receivingMethod,
        pickupDate: this.startDate,
        returnDate: this.endDate,
        rentalPriceTotal: this.rentalPriceTotal,
        bailTotal: this.bailTotal,
        grandTotal: Number(this.grandTotal),
        rentalDiscount: this.rentalDiscount,
        bailDiscount: this.bailDiscount,
        promotionDiscount: this.promotionDiscount,
        // deduction: 0,
        // deductionDate: "2019-07-14T09:40:20.607Z",
        // bailReturningAmount: 0,
        // bailReturningStatus: "string",
        // bailReturningDate: "2019-07-14T09:40:20.607Z",
        description: "",
        state: true,
        // createDateTime: "",
        createBy: this.currentUser,
        // updateDateTime: "",
        updateBy: this.currentUser
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/" + this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          this.orderHeader = await responseOrder.data;
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.orderStatus = "รอรับชุด";
          }, 300);
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 300);
      }

      // >>>> START TO SAVE HEADER
    },
    // >>>>>> เราต้องการ this.itemsInBranch
    async init(branchId) {
      var allInBranch = await this.loadStock(branchId);
      this.branchStock = await allInBranch;

      // console.log(" branchStock >>>> ", this.branchStock);

      // LOAD ALL ITEMS
      var allProduct = await this.loadProduct();
      this.originalList = await allProduct;
      this.itemsInStock = await allProduct;

      // console.log(" <<<<< itemsInStock >>>> ", this.itemsInStock);

      // INITAILIZE ITEMS in BRANCH

      for (var i = 0; i < allInBranch.length; i++) {
        var anItem = await allProduct.find(
          x => x.productItemId === allInBranch[i].productItemId
        );

        // console.log("[", i, "]  >>>> anITEM >>>> ", anItem);

        if (anItem !== null && anItem !== undefined) {
          if (anItem.mainImage) {
            anItem.mainImage =
              (await this.$store.state.imageStore) + anItem.mainImage;
          } else {
            anItem.mainImage =
              (await this.$store.state.imageStore) + "defaultImage.png";
          }

          // ADDITIONAL FIELD
          anItem.amountInBranch = allInBranch[i].inStock;
          anItem.reservedQuantity = 1;
          anItem.notAvailable = false;

          this.itemsInBranch.push(anItem);
        }
      }

      // console.log("this.itemsInBranch >>>> ", this.itemsInBranch);
    },

    async loadProduct() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadStock(branchId) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    // >>>>>> เราต้องการ this.displayItems
    // async comeback(orderid) {
    //   var order = await this.loadOrder(orderid);
    //   var dlist = await this.loadDetail(orderid);
    //   this.detail = await dlist;

    //   this.orderNumber = order.orderNumber;

    //   // console.log("@FIRST %%% order >>> ", order);

    //   this.currentBranchId = await order.branchId;

    //   await this.init(this.currentBranchId);

    //   // console.log("this.currentBranchId >>> ", this.currentBranchId);

    //   // orderStatus "saved";

    //   // customerId "string";
    //   this.orderNumber = await order.orderNumber;
    //   this.customerName = await order.customerName;
    //   this.customerAddress = await order.customerAddress;
    //   this.customerPhone = await order.customerPhone;
    //   this.receivingMethod = await order.receivingMethod;
    //   this.startDate = await order.pickupDate;
    //   this.endDate = await order.returnDate;
    //   this.rentalPriceTotal = await order.rentalPriceTotal;
    //   this.bailTotal = await order.bailTotal;
    //   this.grandTotal = await order.grandTotal;
    //   this.rentalDiscount = await order.rentalDiscount;
    //   this.bailDiscount = await order.bailDiscount;
    //   this.promotionDiscount = await order.promotionDiscount;
    //   this.orderStatus = await order.orderStatus;
    //   this.remark1 = await order.remark1;

    //   this.aBranch = await this.getBranchShare(this.currentBranchId);

    //   // LOAD DETAIL
    //   var list = [];
    //   for (var d = 0; d < dlist.length; d++) {
    //     var anItem = await this.itemsInBranch.find(
    //       x => x.productItemId === dlist[d].productItemId
    //     );
    //     anItem.reservedQuantity = dlist[d].reservedQuantity;
    //     anItem.removed = dlist[d].flag1;
    //     if (dlist[d].flag1 === "y") {
    //       anItem.checked = true;
    //     } else {
    //       anItem.checked = false;
    //     }

    //     await this.displayItems.push(anItem);
    //     await list.push(anItem);
    //   }

    //   // console.log("this.displayItems >>> ", this.displayItems);

    //   return list;
    // },


    //********** 18May2022 ***********/


    async comeback(orderid) {

      var list = [];
      // this.orderHeaderId = this.$route.params.orderid;
      // this.currentUser = JSON.parse(localStorage.getItem("username"));

      var resp = null;
      try {
        resp = await this.$http.post(
          this.$store.state.apiURL + "/api/orderheader/receipt-info/" + orderid,
          {},
          this.$store.state.auth.apiHeader
        );
        this.orderInfo = resp.data;

          // console.log("this.orderInfo >>> ",resp.data);

        this.currentBranchId =  this.orderInfo.orderHeader.branchId;

        this.orderNumber =  this.orderInfo.orderHeader.orderNumber;
        this.customerName =  this.orderInfo.orderHeader.customerName;
        this.customerAddress =  this.orderInfo.orderHeader.customerAddress;
        this.customerPhone =  this.orderInfo.orderHeader.customerPhone;
        this.receivingMethod =  this.orderInfo.orderHeader.receivingMethod;
        this.startDate =  this.orderInfo.orderHeader.pickupDate;
        this.endDate =  this.orderInfo.orderHeader.returnDate;
        this.rentalPriceTotal =  this.orderInfo.orderHeader.rentalPriceTotal;
        this.bailTotal =  this.orderInfo.orderHeader.bailTotal;
        this.grandTotal =  this.orderInfo.orderHeader.grandTotal;
        this.rentalDiscount =  this.orderInfo.orderHeader.rentalDiscount;
        this.bailDiscount =  this.orderInfo.orderHeader.bailDiscount;
        this.promotionDiscount =  this.orderInfo.orderHeader.promotionDiscount;
        this.orderStatus =  this.orderInfo.orderHeader.orderStatus;
        this.remark1 =  this.orderInfo.orderHeader.remark1;



        //*** BEGIN 15Nov2021 เพิ่มภาษี */
        // this.voucherDiscount = this.orderInfo.orderHeader.voucherDiscount;
        // this.pointDiscount = this.orderInfo.orderHeader.pointDiscount;
        // this.netPrice = this.orderInfo.orderHeader.netPrice;
        // this.vat = this.orderInfo.orderHeader.vat;
        // this.beforeVat = this.orderInfo.orderHeader.beforeVat;
        // this.inVat = this.orderInfo.orderHeader.inVat;
        // this.vatSystem = this.orderInfo.orderHeader.vatSystem;
        // this.getPoint = this.orderInfo.orderHeader.getPoint;
        // this.taxRate = this.orderInfo.orderHeader.taxRate;
         //*** END 15Nov2021 เพิ่มภาษี */

        this.detail =  this.orderInfo.orderDetail;
        var dlist =  this.orderInfo.orderDetail;


        for (var d = 0; d < dlist.length; d++) {
          var theItm = {};
          theItm  = Object.assign({}, dlist[d])
          theItm.removed =  dlist[d].flag1;
          this.displayItems.push(theItm);
          this.orders.push(theItm);
          list.push(theItm);
        }

        // this.shopInfo = this.orderInfo.shopInfo;
        this.shopinfo = this.orderInfo.shopInfo;

        this.aBranch = this.orderInfo.branch;


      } catch (err) {
        this.$swal({
          icon: 'warning',
          title: 'ไม่สามารถรับข้อมูลได้',
        });
      }



      return list;
    },

    async loadOrder(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderheader/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.orders.length; i++) {
        total += this.orders[i].rentalPrice;
      }
      return total;
    },
    // goback
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.orders[indextr].reservedQuantity = 1;
      this.orders.splice(indextr, 1);
      console.log(this.orders);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    }
  },
  async created() {
    // var rp = this.$route.params.orderno;
    // alert(rp);



    // INITIALIZE DATA

    // 1. ORDER HEADER
    this.orderStatus = 0; // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed

    // 2. CUSTOMER
    this.customerInfo = "";
    this.customerName = "";
    this.customerAddress = "";
    this.customerPhone = "";

    // 3. DELIVERY
    this.howtoreceive = "มารับเอง"; // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
    this.receivingDate = null;
    this.returnDate = null;
    this.addtocart = "";

    // 4. MONETARY

    // this.bailDiscount = 300;
    // this.rentalDiscount = 100;
    this.promotionDiscount = 0;

    this.rentalPriceTotal = 0;
    this.bailTotal = 0;
    this.grandTotal = 0;
  },
  components: {
    Prism,
    Datepicker,
    CurrencyValue,
    VueBarcode
  },

  computed: {},

  watch: {}
};
</script>

<style >
[dir] .vs-con-table .vs-con-tbody {
  overflow: hidden !important;
}

body {
  background-color: white !important;
}
.vx-card {
  padding-top: 0 !important;
  box-shadow: none !important;
}

.vx-card__body {
  padding: 0 !important;
}

input {
  width: 100%;
}
.theLabel {
  font-weight: bold;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}

.con-tablex vs-table--content{
  overflow: hidden !important;
}

.grand-total {
  font-weight: bold;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}
.grand-total-discount {
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}
.table-font {
  font-family: "Mitr";
  font-size: 14px;
}

.the-black {
  color: #494949;
}

.the-blue {
  color: #4a90e2;
}
.the-green {
  color: #7ed321;
}
.the-red {
  color: #e95455;
}
.vue-barcode-element {
  width: 200px !important;
  height: 90px;
}
.notChoose {
  width: 50px;
}

.page {
  width: 210mm;
  min-height: 291mm;
  padding: 4mm 4mm 2mm;
  margin: 0 auto;
  border: 1px #d3d3d3 solid;
  /* border-radius: 5px; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  /* .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  } */

  .page {
  background-color: white;
  margin: 0 auto !important ;
  font-family: "Mitr";
  position: relative !important;
  border: 0 white solid;
}

.page-sticker {
  font-family: "Mitr";
}


  body {
    background-color: white !important;
  }
}
</style>
